$(document).ready(function() { 


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * slide-content
	// * 
	// *
	$(".slide-content-btn").click(function(){
		var target = $(this).data("content");

		$(this).addClass("active").parents("li").siblings().find("a").removeClass("active");
		$("section.slide-content").find("#slide-content-" + target).slideToggle().siblings("article").slideUp();

		$('html, body').animate({
			scrollTop: $("#slide-content-" + target).offset().top
		}, 500);
	});



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Nav Active when Element is in Viewpoint
    // *
	// * 
	var sections = $(".nav-section"), 
    nav = $("nav.section-nav ul"), 
    nav_height = nav.outerHeight();
    
	$(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 40;
     
      sections.each(function() {
        var top = $(this).offset().top - nav_height, bottom = top + $(this).outerHeight();
     
        if (cur_pos >= top && cur_pos <= bottom) {
          nav.find('li').removeClass('active');
          sections.removeClass('active');
     
          $(this).addClass('active');
          nav.find('a[href="#'+$(this).attr('id')+'"]').parent('li').addClass('active');
        }
      });
    });
	$('nav.section-nav ul li:first-child').addClass('active');




	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * Scroll-To
	// *
	// * Smooth-Scroll to targets on page
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top - 50
			}, 1000);
			return false;
			}
		}
	});


	// * * * * * * * * * * * * * * * * * * * * * * * * *
	// * sticky header
	// * 
	// *
	$(window).scroll(function() {
		if($(window).scrollTop() >= 150) {
			$("header.sticky").addClass("active");
		}else {
			$("header.sticky").removeClass("active");
		}
	});


});